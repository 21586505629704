<template>
  <div class="container-fluid bg-color ">
    <section class="row justify-content-between">
      <p class=" col-3 text-color mt-2">Copyright IronShieldTech LLC</p>
      <p class="col-3 text-color mt-2">Veteran Owned</p>
    </section>

  </div>
</template>


<script>

export default {
  setup() {
    return {}
  }
};
</script>


<style lang="scss" scoped>
.bg-color {
  background-color: #303c4c;
}

.text-color {
  color: #ffffff;
}
</style>