import { createRouter, createWebHistory } from "vue-router";
import Home from "@/pages/HomePage.vue";
import Services from "@/pages/ServicesPage.vue"
import Team from "@/pages/TeamPage.vue"

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
  },
  {
    path: '/services',
    name: 'Services',
    component: Services,
  },
  {
    path: '/team',
    name: 'Team',
    component: Team,
  }
]

export const router = createRouter({
  history: createWebHistory(),
  routes
})