<template>
  <nav class="navbar navbar-expand-md container-fluid justify-content-between p-0 nav-color">
    <section class="row justify-content-between flex-fill m-0">
      <div class="col-md-4 align-items-center p-2">
        <section class="row  flex-fill justify-content-start">
          <div class="col-3 p-0">
            <a class="nav-link pointer text-white p-0" aria-current="page" href="/">
              <img class="logo" src="../assets/logo.png" alt="">
            </a>
          </div>
          <div class="col-8 p-0 text-start d-flex align-items-center">
            <h2 class="">IronShield Tech </h2>
          </div>
        </section>
      </div>
      <div class=" d-flex flex-column col-md-3 p-0">
        <!-- This is the portal button -->
        <section class="row justify-content-center m-0">
          <button class=" col-md-8 text-center btn text-dark button-style-two m-2 " data-bs-toggle="modal"
            data-bs-target="#contactForm">Contact
            Us</button>
        </section>
        <section class="row">
          <!-- This is the collapse button/ Hamburger -->
          <button class=" col-12 navbar-toggler custom-toggler" type="button" data-bs-toggle="collapse"
            data-bs-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
            <span class="navbar-toggler-icon"></span>
          </button>
        </section>

        <!-- This is full navbar row -->
        <section class=" row justify-content-start collapse navbar-collapse p-0 m-0" id="navbarNav">
          <ul class=" col-12 navbar-nav pe-0 m-0">
            <section class="row flex-fill m-0 justify-content-end align-content-end">
              <li class=" col-4 nav-item p-0 m-0">
                <a class="nav-link pointer text-white p-0" aria-current="page" href="/"><span
                    class="text-dark button-border pb-0 mx-1 underline" data-bs-target="#navbarNav"
                    data-bs-toggle="collapse">Home</span></a>
              </li>
              <li class=" col-4 nav-item p-0">
                <a class=" nav-link pointer text-white p-0" aria-current="page" href="/services">
                  <span class="text-dark button-border pb-0 mx-1 underline" data-bs-target="#navbarNav"
                    data-bs-toggle="collapse">Services</span>
                </a>
              </li>
              <li class=" col-4 nav-item p-0">
                <a class="nav-link pointer text-white p-0" aria-current="page" href="/team">
                  <span class="text-dark  button-border pb-0 mx-1 underline" data-bs-target="#navbarNav"
                    data-bs-toggle="collapse">The Team</span>
                </a>
              </li>
            </section>
          </ul>
        </section>
      </div>
    </section>
  </nav>
</template>


<script>

export default {
  setup() {



    return {


    }
  }
};
</script>


<style lang="scss" scoped>
.logo {
  height: 13vh;
}

.navbar-mine {
  height: 10%;
  border-bottom: 4px solid black;
}

.button-style-two {
  border: 2px solid #08494B;
  z-index: 3;
}

.nav-color {
  background-color: #dedddb;
}
</style>