<template>

  <div id="services" class="container size">

    <section class="row justify-content-evenly">
      <div class="col-12 m-5 text">
        <h1>Custom Web Design</h1>
        <section class="row justify-content-evenly">
          <div class="col-md-3">
            <a class="text-dark" href="https://amsg-assoc.com/#/">
              <h3>AMSG Associates</h3>
              <img class="projectImage img-fluid" src="../assets/amsg.png" alt="">
            </a>
          </div>
          <div class="col-md-3">
            <h3>Brio Hair Space</h3>
            <img class="projectImage img-fluid" src="../assets/Soon.png" alt="">
          </div>
          <div class="col-md-3">
            <h3>Clara Beauty Co.</h3>
            <img class="projectImage img-fluid" src="../assets/Soon.png" alt="">
          </div>
          <div class="col-md-3">
            <h3>Crystal Clear Exterior</h3>
            <img class="projectImage img-fluid" src="../assets/Soon.png" alt="">
          </div>
        </section>
      </div>
      <section class="row">
        <div class="col-12">
          <section class="row">
            <h1>Code Partnership</h1>
          </section>
          <section class="row">
            <p>We would love to work together to help you reach your dreams!</p>
          </section>
        </div>
      </section>
      <section class="row">
        <div class="col-12">
          <section class="row">
            <h1>Low/No-Code Customizations</h1>
          </section>
          <section class="row">
            <p>Do you have a current website using Wix, Shopify, Square, or WordPress? We would love to help you give it
              a more customized feel. </p>
          </section>
        </div>
      </section>
    </section>
    <section class="row">
      <div>
        <button class="btn text-dark button-style-two m-2 " data-bs-toggle="modal" data-bs-target="#contactForm">
          ContactUs
        </button>
      </div>
    </section>
  </div>
</template>


<script>
import { onMounted } from "vue";



export default {

  setup() {

    onMounted(() => {
      setScrollVar();

    })

    window.addEventListener("scroll", setScrollVar)
    window.addEventListener("resize", setScrollVar)
    function setScrollVar() {
      const templateElement = document.documentElement
      const scrollCount = templateElement.scrollTop / templateElement.clientHeight
      console.log(Math.min(scrollCount * 100, 100))
      templateElement.style.setProperty(
        "--scroll",
        Math.min(scrollCount * 100, 100)
      )
    }




    return {

    }
  },


};
</script>


<style lang="scss" scoped>
.button-style-two {
  border: 2px solid #08494B;
  z-index: 3;
}

.projectImage {
  height: 50vh;
}

a:link {
  text-decoration: none;
}
</style>