<template>
  <div id="home" class="container home">
    <!-- HeroImage Section -->
    <section class="row">
      <div class="col-md-6">
        <img class="heroimg img-fluid" src="../assets/logo.png" alt="">
      </div>
      <div class="col-md-6 d-flex flex-column justify-content-center">
        <!-- Mission Statement Section -->
        <section class="row p-5">
          <h5 class="lh-lg fs-5"> <span class="fw-bold fs-4 text-uppercase">Our Mission </span>is to empower businesses
            and
            individuals
            with innovative, tailor-made web solutions that
            drive growth, enhance user experiences, and unlock their full digital potential.
          </h5>
        </section>
        <section class="row">
          <div>
            <button class="btn text-dark button-style-two m-2 " data-bs-toggle="modal"
              data-bs-target="#contactForm">Contact
              Us</button>
          </div>
        </section>
      </div>
    </section>

  </div>
</template>


<script>
import { onMounted } from "vue";



export default {

  setup() {

    onMounted(() => {
    })

    return {

    }
  },


};
</script>


<style lang="scss" scoped>
.heroimg {
  max-height: 75vh;
}

.button-style-two {
  border: 2px solid #08494B;
  z-index: 3;
}
</style>